import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { serverConfig } from '../config';
import translationEn from './translation.en.json';
import translationJa from './translation.ja.json';
import translationKo from './translation.ko.json';

const resources = {
  en: {
    translation: translationEn,
  },
  ko: {
    translation: translationKo,
  },
  ja: {
    translation: translationJa,
  },
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: serverConfig.useLang ? 'en' : 'ko', // use en if detected lng is not available
    debug: process.env.NODE_ENV === 'development',
    // keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    supportedLngs: serverConfig.useLang ? Object.keys(resources) : ['ko'],
  });

export default i18n;
