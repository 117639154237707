export const PLAYPOT_SERVER = process.env.REACT_APP_PLAYPOT_SERVER;
export const COMMUNITY_SERVER = process.env.REACT_APP_COMMUNITY_SERVER;

const googleId = '222850624848-qrjingh2vkmjtrihha47fga3ijd5h2v2.apps.googleusercontent.com';
const facebookId = '1682316208614800';
const twitterLoginUri = '/api/v1/auth/twitter';
const twitterRequestTokenUrl = '/api/v1/auth/twitter/reverse';

/**
 * 개발용 설정
 */
const devConfigType = process.env.REACT_APP_DEV_SERVER || 'devServer';
const developConfig: { [x in string]: IServerConfig } = {
  local: {
    mode: 'playpot',
    appUrl: 'http://localhost:3000',
    googleId,
    facebookId,
    twitterLoginUrl: 'http://localhost:8001' + twitterLoginUri,
    twitterRequestTokenUrl: 'http://localhost:8001' + twitterRequestTokenUrl,
    serverHost: 'http://localhost:8001',
    externalApiHost: 'https://api.playpot.net/games-api',
    useLang: true,
  },
  localWsl: {
    mode: 'wsl',
    appUrl: 'http://localhost:3000',
    googleId,
    facebookId,
    twitterLoginUrl: 'http://localhost:8001' + twitterLoginUri,
    twitterRequestTokenUrl: 'http://localhost:8001' + twitterRequestTokenUrl,
    serverHost: 'http://localhost:8001',
    externalApiHost: 'https://graphql-dev.playpot.net/games-api',
    useLang: true,
  },
  devServer: {
    mode: 'playpot',
    appUrl: 'https://dev.playpot.net',
    googleId,
    facebookId,
    twitterLoginUrl: 'https://dev.playpot.net/' + twitterLoginUri,
    twitterRequestTokenUrl: 'https://dev.playpot.net' + twitterRequestTokenUrl,
    serverHost: 'https://api-dev.playpot.net',
    externalApiHost: 'https://api-dev.playpot.net/games-api',
    useLang: true,
  },
};

/**
 * 배포용 설정
 */
const productConfigType = process.env.REACT_APP_PRODUCT_CONFIG || 'product';
const productConfig: { [x in string]: IServerConfig } = {
  dev: {
    mode: 'playpot',
    appUrl: 'https://dev.playpot.net',
    googleId,
    facebookId,
    twitterLoginUrl: 'https://dev.playpot.net/' + twitterLoginUri,
    twitterRequestTokenUrl: 'https://dev.playpot.net' + twitterRequestTokenUrl,
    serverHost: 'https://api-dev.playpot.net',
    externalApiHost: 'https://api-dev.playpot.net/games-api',
    useLang: true,
  },
  test: {
    mode: 'playpot',
    appUrl: 'https://test.playpot.net',
    googleId,
    facebookId,
    twitterLoginUrl: 'https://test.playpot.net/' + twitterLoginUri,
    twitterRequestTokenUrl: 'https://test.playpot.net' + twitterRequestTokenUrl,
    serverHost: 'https://api-test.playpot.net',
    externalApiHost: 'https://api-test.playpot.net/games-api',
    useLang: true,
  },
  testWsl: {
    mode: 'wsl',
    appUrl: 'https://wsl-test.playpot.net',
    googleId,
    facebookId,
    twitterLoginUrl: 'https://test.playpot.net/' + twitterLoginUri,
    twitterRequestTokenUrl: 'https://test.playpot.net' + twitterRequestTokenUrl,
    serverHost: 'https://api-test.playpot.net',
    externalApiHost: 'https://api-test.playpot.net/games-api',
    useLang: true,
  },
  product: {
    mode: 'playpot',
    appUrl: 'https://playpot.net',
    googleId,
    facebookId,
    twitterLoginUrl: 'https://api.playpot.net/' + twitterLoginUri,
    twitterRequestTokenUrl: 'https://api.playpot.net' + twitterRequestTokenUrl,
    serverHost: 'https://api.playpot.net',
    externalApiHost: 'https://api.playpot.net/games-api',
    useLang: true,
  },
  productWsl: {
    mode: 'wsl',
    appUrl: 'https://wsl.playpot.net',
    googleId,
    facebookId,
    twitterLoginUrl: 'https://api.playpot.net/' + twitterLoginUri,
    twitterRequestTokenUrl: 'https://api.playpot.net' + twitterRequestTokenUrl,
    serverHost: 'https://api.playpot.net',
    externalApiHost: 'https://api.playpot.net/games-api',
    useLang: true,
  },
};

export const serverConfig: IServerConfig =
  process.env.NODE_ENV === 'development'
    ? developConfig[devConfigType]
    : productConfig[productConfigType];

export const isWsl = serverConfig.mode === 'wsl';
